import React, { useState, useEffect } from 'react';
import { json, resolvePath, useNavigate } from "react-router-dom";
import {
  Container,
  DepthBox,
  TopBarBlue,
  TitleUnit,
  RightDiv,
  ParallelInputWrapper,
  MenuText,
  TitleEmployee,
  LeftDiv,
  SelectWrapper,
  SelectCityWrapper,
  BlueButtonWrapper,
  LoadingSpinner,
  LoadingContainer,
  MessageWrapper,
  ListButtonDiv,
  ListItensDiv,
  ConfirmaButtonDiv,
  DivPrimaria
} from './styles';
import ModalPassword from '../../utils/components/ModalPassword';
import InputFields from '../../utils/components/InputFields';
import BlueButton from '../../utils/components/BlueButton';
import InputQuery from '../../utils/components/InputQuery';
import InputSmall from '../../utils/components/InputSmall';
import Select from '../../utils/components/Select';
import NumberSelector from '../../utils/components/NumberSelector';
import TimePicker from '../../utils/components/TimePicker';
import DaySelector from '../../utils/components/DaySelector';
import Menu from '../../utils/components/Menu';
import Message from '../../utils/components/Message';

import getEstadosDisponiveis from '../../data/services/getState';
import getUnidades from '../../data/services/getUnidades';
import getFuncoes from '../../data/services/getFuncoes';
import getFuncionarios from '../../data/services/getFuncionarios';
import getUnidadeDados from '../../data/services/getUnidadeDados';
import getFuncionarioDados from '../../data/services/getFuncionarioDados';
import getRotas from '../../data/services/getRotas';
import getPermissoes from '../../data/services/getPermissoes';
import changePass from '../../data/services/changePass';
import TopBar from '../../utils/components/TopBar';

import getTimeOut from '../../data/services/getTimeOut';
import PopUpErro from '../../utils/components/PopUpErro';
import { loginPath, resultadoPath, melhorUnidadePath, consultaPath } from '../../data/pathConfig';
import OptionSelector from '../../utils/components/OptionSelector';
import ListUnidades from '../../utils/components/ListaUnidades';
import BlueButtonConfirmed from '../../utils/components/BlueButtonConfirmed';

import { FaArrowDownLong, FaArrowUpLong } from "react-icons/fa6";
import getMelhoresUnidades from '../../data/services/getMelhoresUnidades';

import stringSimilarity from 'string-similarity';
import ModalErro from '../../utils/components/ModalErro';
import getCidadesDisponiveis from '../../data/services/getCidadesDisponiveis';

const Home = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(
    localStorage.getItem('token') ? localStorage.getItem('token') : '');
  const [Password, setPassword] = useState('');
  const [NewPassword, setNewPassword] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ConfirmPassword, setConfirmPassword] = useState('');
  const [estado, setEstado] = useState(
    localStorage.getItem('estado') ? JSON.parse(localStorage.getItem('estado')) : []);
  const [NumeroDia, setNumeroDia] = useState(
    localStorage.getItem('numero_dias')? localStorage.getItem('numero_dias') : '22');
  const [valorRaio, setValorRaio] = useState(
    localStorage.getItem('valor_raio') ? localStorage.getItem('valor_raio') : 1);
  const [selectDias, setSelectDias] = useState(
    localStorage.getItem('selected_dias') ? localStorage.getItem('selected_dias').split(',') : ['U'] 
  );
  const [HoraEntrada, setHoraEntrada] = useState(
    localStorage.getItem('hora_entrada') ? localStorage.getItem('hora_entrada') : '08:00'
  );
  const [HoraSaida, setHoraSaida] = useState(
    localStorage.getItem('hora_saida') ? localStorage.getItem('hora_saida') : '17:00'
  );
  const [funcionario, setFuncionario] = useState(
    localStorage.getItem('funcionario_selecionado') ? localStorage.getItem('funcionario_selecionado') : '');
  const [funcaoSelecionada, setFuncaoSelecionada] = useState(
    localStorage.getItem('selected_funcao') ? localStorage.getItem('selected_funcao') : ''
  );
  const [logradouro, setLogradouro] = useState(
    localStorage.getItem('logradouro') ? localStorage.getItem('logradouro') : '');
  const [numero, setNumero] = useState(
    localStorage.getItem('numero') ? localStorage.getItem('numero') : '');
  const [bairro, setBairro] = useState(
    localStorage.getItem('bairro') ? localStorage.getItem('bairro') : '');
  const [cep, setCep] = useState(
    localStorage.getItem('cep') ? localStorage.getItem('cep') : '');
  const [cidade, setCidade] = useState(
    localStorage.getItem('cidade') ? localStorage.getItem('cidade') : '');
  const [unidade, setUnidade] = useState([]);
  const [unidadeId, setUnidadeId] = useState(
    localStorage.getItem('unidade_id') ? localStorage.getItem('unidade_id') : '');
  const [logradouroUnidade, setLogradouroUnidade] = useState(
    localStorage.getItem('logradouro_unidade') ? localStorage.getItem('logradouro_unidade') : '');
  const [numeroUnidade, setNumeroUnidade] = useState(
    localStorage.getItem('numero_unidade') ? localStorage.getItem('numero_unidade') : '');
  const [bairroUnidade, setBairroUnidade] = useState(
    localStorage.getItem('bairro_unidade') ? localStorage.getItem('bairro_unidade') : '');
  const [cepUnidade, setCepUnidade] = useState(
    localStorage.getItem('cep_unidade') ? localStorage.getItem('cep_unidade') : '');
  const [cidadeUnidade, setCidadeUnidade] = useState(
    localStorage.getItem('cidade_unidade') ? localStorage.getItem('cidade_unidade') : '');
  const [estadoUnidade, setEstadoUnidade] = useState(
    localStorage.getItem('estado_unidade') ? localStorage.getItem('estado_unidade') : '');
  const [funcionarioslimpos, setFuncionariosLimpos] = useState(
    localStorage.getItem("func_limpos") ? JSON.parse(localStorage.getItem("func_limpos")) : []);
  const [funcoes, setFuncoes] = useState([]);
  const [cidades, setCidades] = useState(
    localStorage.getItem('cidades') ? JSON.parse(localStorage.getItem('cidades')) : []);
  const [loading, setLoading] = useState(false);

  // Tratamento de erros
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const [canEdit, setCanEdit] = useState(false);
  const [editaFuncionario, setEditaFuncionario] = useState(false);

  const [tipoConsulta, setTipoConsulta] = useState(
    localStorage.getItem('tipo_consulta') ? localStorage.getItem('tipo_consulta') : 'TOD');
  const [unidadesDisponiveis, setUnidadesDisponiveis] = useState([]);
  const [unidadesSelecionadas, setUnidadesSelecionadas] = useState([]);
  const [podeConsultarMU, setPodeConsultarMU] = useState(false);
  const [botaoInserirTudo, setBotaoInserirTudo] = useState(true);
  const [botaoRetirarTudo, setBotaoRetirarTudo] = useState(false);
  const[funcionarioSelected, setFuncionarioSelected] = useState(true);

  const [estadoSel, setEstadoSel] = useState(
    localStorage.getItem("estado_selecionado") ? localStorage.getItem("estado_selecionado").replace(/['"]/g, "") : ""
  );

  // opções para o select 
  const funcOptions = [
    { value: '', label: 'Candidato Personalizado' },
    ...funcionarioslimpos.map((item) => ({
      value: item.funcionario,
      label: item.funcionario
    }))
  ];

  const defaultOption = { value: '', label: 'Selecione uma cidade' };
  const cidadeOpcao = Array.isArray(cidades) ? 
    [defaultOption, ...cidades.map((item) => {
      return { value: item, label: item };
    })] 
    : [defaultOption];

  const funcoesoptions = [
    { value: '', label: 'Todas as funções' },
    ...funcoes.map((item) => ({
      value: item.value,
      label: item.optionText
    }))
  ];

  const unitOptions = [];
  unitOptions.push({ value: '', label: 'Escolha uma unidade' });
  for (const key in unidade) {
    if (unidade.hasOwnProperty(key)) {
      const option = { value: unidade[key].id, label: unidade[key].nome };
      unitOptions.push(option);
    }
  }

  useEffect(() => {
    const verify = async (token) => {
      const data = await getTimeOut(token);
      if(!data.success) {
        navigate(loginPath);
      }
    }

    if (token) {
      verify(token);      
    } else {
      navigate(loginPath);
    }
  });

  // get nas unidades
  useEffect(() => {
    const unidadesEffect = async () => {
      console.log(estadoSel); 
      let params = {
        estado: estadoSel
      };
      const response = await getUnidades(token, params);
      if (response.success === true) {
        setUnidade(response.data);
      }
    };
    unidadesEffect();
  }, [token, estadoSel]);

  // set da lista de unidades disponíveis
  useEffect(() =>{
    if (localStorage.getItem('unidades_disponiveis') && localStorage.getItem('unidades_selecionadas')) {
      setUnidadesDisponiveis(JSON.parse(localStorage.getItem('unidades_disponiveis')));
      setUnidadesSelecionadas(JSON.parse(localStorage.getItem('unidades_selecionadas')));
    } else {
    let aux = [];
      for(const key in unidade) {
        if (unidade.hasOwnProperty(key)) {
          aux.push(unidade[key].nome);
        }
      }
      setUnidadesDisponiveis(aux);
    }
  }, [unidade]);

  // get nas funções dos funcionarios
  useEffect(() => {
    const funcoesEffect = async () => {
      const response = await getFuncoes(token);
      const resposta = response.data;
      const regex = /value="([^"]+)">([^<]+)<\/option>/g;
      let match;
      const funcoesArray = [];

      while ((match = regex.exec(resposta)) !== null) {
        const value = match[1];
        const optionText = match[2];
        funcoesArray.push({ value, optionText });
      }
      setFuncoes(funcoesArray);
    };
    funcoesEffect();
  }, [token]);

  // get nos funcionarios
  useEffect(() => {
    const funcionariosEffect = async () => {
      let response = "";
      const params = {
        'funcao': funcaoSelecionada
      };
      if (funcaoSelecionada == "") {
        response = await getFuncionarios(token);
      } else {
        response = await getFuncionarios(token, params);
      }
      const resposta = response.data;
      const regex = /value='(\d+)'>(\d+) - ([^<]+)<\/option>/g;
      const funcionariosArray = [];

      let match;
      while ((match = regex.exec(resposta)) !== null) {
        const funcionario = {
          funcionario: match[1] + ' - ' + match[3]
        };
        funcionariosArray.push(funcionario);
      }
      setFuncionariosLimpos(funcionariosArray);
      localStorage.setItem("func_limpos", JSON.stringify(funcionarioslimpos));
    };
    localStorage.setItem('selected_funcao', funcaoSelecionada);
    funcionariosEffect();
  }, [funcaoSelecionada]);

  //get dos estados
  useEffect(() => {
    const estadosEffect = async () => {
      const response = await getEstadosDisponiveis(token);
      if (response.success == true) {
        setEstado(response.data.estado);
      }
    };
    if (estado.length == 0 || cidades.length == 0) {
      estadosEffect();
    }
  }, [token]);

  //get nos dados da unidade
  useEffect(() => {
    const unidadeDados = async () => {
      const UnidadeParams = {
        id: unidadeId
      };
      if (unidadeId === '') {
        return;
      }
      const response = await getUnidadeDados(token, UnidadeParams);
      if (response.success) {
        if (response.data) {
          setBairroUnidade(response.data.bairro);
          setCidadeUnidade(response.data.cidade);
          setCepUnidade(response.data.cep);
          setEstadoUnidade(response.data.estado);
          setLogradouroUnidade(response.data.logradouro);
          setNumeroUnidade(response.data.numero);
        } else {
          console.error('Dados de unidade indefinidos ou vazios.');
        }
      }

    };
    unidadeDados();
  }, [unidadeId]);

  // get nos dados do funcionario
  useEffect(() => {
    if (cidades.length === 0) {
      console.error('Cidades array is empty. Skipping API call.');
      return;
    }

    if (funcionario) {
      //console.log(funcionario);
      const funcionarioDados = async () => {
        const FuncionarioParams = {
          id: funcionario
        };
        const response = await getFuncionarioDados(token, FuncionarioParams);
        if (response.success) {
          if (response.data) {
            //console.log(response.data);
            setUnidadeId(response.data.id_unidade);
            setBairro(response.data.bairro);
  
            // Transform cidades if it's not already an array of strings
            let cidadesArray = [];
            if (Array.isArray(cidades)) {
              cidadesArray = cidades;
            } else if (typeof cidades === 'object' && cidades !== null) {
              cidadesArray = Object.values(cidades);
            }
            
            // Ensure response.data.cidade is a string and cidadesArray is an array of strings
            if (typeof response.data.cidade === 'string' && Array.isArray(cidadesArray) && cidadesArray.every(cidade => typeof cidade === 'string')) {
              const formattedCity = response.data.cidade
                .toLowerCase()
                .split(' ')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
              
              const { bestMatch } = stringSimilarity.findBestMatch(formattedCity, cidadesArray);
              setCidade(bestMatch.target);
            } else {
              //console.error('Invalid city data or city list.');
            }
  
            setCep(response.data.cep);
            setLogradouro(response.data.logradouro);
            setNumero(response.data.numero);
            if (response.data.funcaoSelecionada) {
              setFuncaoSelecionada(response.data.funcaoSelecionada);
            }
            localStorage.setItem('funcionario_selecionado', funcionario);
          } else {
            //console.error('Dados de funcionário indefinidos ou vazios.');
          }
        }
      };
      funcionarioDados();
    }
  }, [funcionario]);

  useEffect(() => {
    const findPermissoes = async () => {
      const response = await getPermissoes(token);
      if (response.success) {
        if (response.data[0] == "Master") {
          setCanEdit(true);
        } else {
          setCanEdit(false);
        }
      }
    };
    findPermissoes();
  }, [token]);

  useEffect(() => {
    if (unidadesSelecionadas.length > 1) {
      setPodeConsultarMU(true);
    } else {
      setPodeConsultarMU(false);
    }

    if (unidadesDisponiveis.length > 0) {
      setBotaoInserirTudo(true);
    } else {
      setBotaoInserirTudo(false);
    }

    if (unidadesSelecionadas.length > 0) {
      setBotaoRetirarTudo(true);
    } else {
      setBotaoRetirarTudo(false);
    }
  }, [unidadesSelecionadas, unidadesDisponiveis]);

  useEffect(() => {
    localStorage.setItem('tipo_consulta', tipoConsulta);
  }, [tipoConsulta]);

  useEffect(() => {
    localStorage.setItem('logradouro', logradouro);
  }, [logradouro]);

  useEffect(() => {
    localStorage.setItem('bairro', bairro);
  }, [bairro]);

  useEffect(() => {
    localStorage.setItem('numero', numero);
  }, [numero]);

  useEffect(() => {
    localStorage.setItem('cep', cep);
  }, [cep]);

  useEffect(() => {
    localStorage.setItem('cidade', cidade);
  }, [cidade])

  useEffect(() => {
    localStorage.setItem('logradouro_unidade', logradouroUnidade);
  }, [logradouroUnidade]);

  useEffect(() => {
    localStorage.setItem('numero_unidade', numeroUnidade);
  }, [numeroUnidade]);

  useEffect(() => {
    localStorage.setItem('bairro_unidade', bairroUnidade);
  }, [bairroUnidade]);
  
  useEffect(() => {
    localStorage.setItem('cep_unidade', cepUnidade);
  }, [cepUnidade]);

  useEffect(() => {
    localStorage.setItem('cidade_unidade', cidadeUnidade);
  }, [cidadeUnidade]);

  useEffect(() => {
    localStorage.setItem('estado_unidade', estadoUnidade);
  }, [estadoUnidade]);

  useEffect(() => {
    localStorage.setItem('unidade_id', unidadeId);
  }, [unidadeId]);

  useEffect(() => {
    localStorage.setItem('valor_raio', valorRaio);
  }, [valorRaio]);

  useEffect(() => {
    localStorage.setItem('numero_dia', NumeroDia);
  }, [NumeroDia]);

  useEffect(() => {
    localStorage.setItem('estado', JSON.stringify(estado));
  }, [estado]);

  useEffect(() => {
    localStorage.setItem('cidades', JSON.stringify(cidades));
  }, [cidades]);

  useEffect(() => {
    localStorage.setItem('estado_selecionado', JSON.stringify(estadoSel));
  }, [estadoSel]);

  useEffect(() => {
    if(funcionario) {
      localStorage.setItem("edita_func", false);
    } else {
      localStorage.setItem("edita_func", true);
    }
  }, [funcionario]);

  const limparDados = () => {
    setUnidadeId('');
    setBairro('');
    setCidade('');
    setCep('');
    setLogradouro('');
    setNumero('');
    setLogradouroUnidade('');
    setNumeroUnidade('');
    setBairroUnidade('');
    setCepUnidade('');
    setCidadeUnidade('');
    setEstadoUnidade('');
  }

  const handleSelectDay = (dias) => {
    setSelectDias(dias);
    localStorage.setItem('selected_dias', dias);
  };
  // edição de senha 
  const handleEditPassword = async () => {
    const editparams = {
      senhaAntiga: Password,
      novaSenha: NewPassword,
    }
    const response = await changePass(token, editparams);
    if (response.success == true) {
      setIsMenuOpen(false);
      setIsModalOpen(false);
      alert("A senha foi trocada");
    }
    else {
      alert("Erro ao alterar senha");
    }
  }
  
  const handleSelecionaUnidade = (unidadeId) => {
    if (unidadeId === '') {
      // Limpar os estados dos campos de input da unidade
      setLogradouroUnidade('');
      setNumeroUnidade('');
      setBairroUnidade('');
      setCepUnidade('');
      setCidadeUnidade('');
      setEstadoUnidade('');
    } else {
      const unidadeSelecionada = unidade.find(unit => unit.id === unidadeId);
      if (unidadeSelecionada) {
        setLogradouroUnidade(unidadeSelecionada.logradouro || '');
        setNumeroUnidade(unidadeSelecionada.numero || '');
        setBairroUnidade(unidadeSelecionada.bairro || '');
        setCepUnidade(unidadeSelecionada.cep || '');
        setCidadeUnidade(unidadeSelecionada.cidade || '');
        setEstadoUnidade(unidadeSelecionada.estado || '');
      }
    }
  };
  // função de consulta de rotas
  const handleGetRotas = async () => {
    // se logradouro ou numero estiverem vazios não permitir a consulta
    if (logradouro === '' || numero === '' || logradouroUnidade === '' || numeroUnidade === '') {
      setErrorMessage('Preencha todos os campos!');
      setIsError(true);
      return;
    }

    const params = {
      id_func: funcionario.split(" - ")[0],
      id_unidade: unidadeId,
      logradouro: logradouro,
      numero: numero,
      bairro: bairro,
      cep: cep,
      cidade: cidade,
      estado: estadoSel,
      logradouroD: logradouroUnidade,
      numeroD: numeroUnidade,
      bairroD: bairroUnidade,
      cepD: cepUnidade,
      cidadeD: cidadeUnidade,
      estadoD: estadoUnidade,
      tipoDia: selectDias,
      horarioEntrada: HoraEntrada,
      horarioSaida: HoraSaida,
      dias: NumeroDia,
      raio: valorRaio
    };
    setLoading(true);
    try {
      const response = await getRotas(token, params);
      
      if (response.success === true) {
        setLoading(false);
        navigate(resultadoPath, { state: { rotas: response.data, returnPath: consultaPath } });
      } else {
        setErrorMessage()
        setLoading(false);
      }
    } catch (error) {
      // Se ocorrer um erro durante a requisição
      if (error.response && error.response.status === 500) {
        console.error('Erro ao fazer consulta. Codigo 500.');
      } else {
        console.error('Erro ao processar a requisição.');
      }
      setLoading(false);
    }
    
  }
  //funções auxiliares
  const handleProfileClick = () => { setIsMenuOpen(true);};

  const handleCloseMenu = () => {
    setIsModalOpen(false);
    setIsMenuOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsMenuOpen(false);
    setIsModalOpen(false);
  };

  const handleNumberDaysChange = (value) => { setNumeroDia(value); };

  const handleChangeEndTime = (value) => {
    setHoraSaida(value); 
    localStorage.setItem('hora_saida', value);
  };

  const handleChangeStartTime = (value) => {
    setHoraEntrada(value);
    localStorage.setItem('hora_entrada', value);
  };

  const handleItemClick = (item, fromList, setFromList, toList, setToList) => {
    // Check if the toList is unidadesSelecionadas and if it already has 10 items
    if (toList.length >= 10 && toList === unidadesSelecionadas) {
        setErrorMessage('Você pode selecionar até 10 unidades.');
        setIsError(true);
        return;
    }
    setFromList(fromList.filter(i => i !== item));
    setToList([...toList, item]);
};

  const handleMoveAll = (fromList, setFromList, toList, setToList) => {
    setToList([...toList, ...fromList]);
    setFromList([]);
  };

  const handleGetMelhorUnidade = async () => {

    console.log(cidade);

    if (logradouro === '' || numero === '' || bairro === '' || cidade === '') {
      setErrorMessage('Preencha todos os campos!');
      setIsError(true);
      return;
    }

    if (podeConsultarMU) {

      let ids_unidades = [];

      for (const key in unidade) {
        for (const unid_key in unidadesSelecionadas) {
          if (unidade[key].nome == unidadesSelecionadas[unid_key]) {
            ids_unidades.push(unidade[key].id)
          }
        }
      }

      const ids = ids_unidades.join('_');

      const params = {
        logradouro: logradouro,
        cep: cep,
        bairro: bairro,
        estado: estadoSel,
        ids_unidades: ids,
        numero: numero,
        cidade: cidade,
        matricula: funcionario.split(' - ')[0],
        dias: NumeroDia,
        raio: valorRaio,
        horarioEntrada: HoraEntrada,
        horarioSaida: HoraSaida,
        tipoDia: "U",
        id_func: funcionario.split(' - ')[0]
      }

      setLoading(true);
      localStorage.setItem('unidades_selecionadas' ,JSON.stringify(unidadesSelecionadas));
      localStorage.setItem('unidades_disponiveis', JSON.stringify(unidadesDisponiveis));
      try {
        const response = await getMelhoresUnidades(token, params);
        if (response.success === true) {
          setLoading(false);
          navigate(melhorUnidadePath, { state: { dataState: response.data, funcionarioState: params } });
        } else {
          setErrorMessage(response.error);
          setLoading(false);
        }
      } catch (error) {
        // Se ocorrer um erro durante a requisição
        if (error.response && error.response.status === 500) {
          console.error('Erro ao fazer consulta. Código 500.');
        } else {
          console.error('Erro ao processar a requisição.');
        }
        setIsError(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let params = {
        estado: estadoSel
      };

      const resp = await getCidadesDisponiveis(token, params);

      if(resp.success === true) {
        console.log(resp.data);
        setCidades(resp.data);
      }
    };
    fetchData();
  }, [estadoSel]);

  return (
    <>
      <TopBar onProfileClick={handleProfileClick} estadoSel={estadoSel} setEstadoSel={setEstadoSel} />
      <Container>
        <DepthBox>
          <TopBarBlue>
            <TitleEmployee>Funcionário</TitleEmployee>
            <TitleUnit>Unidade</TitleUnit>
          </TopBarBlue>
          <LeftDiv>
            <SelectWrapper>
              <Select
                label={"Função"}
                name="nome"
                value={funcaoSelecionada}
                placeholder=" "
                options={funcoesoptions}
                onChange={(e) => {
                  setFuncaoSelecionada(e.target.value);
                  setFuncionario('');
                  limparDados();
                }}
              />
              <Select
                label={"Funcionário"}
                name="nome"
                value={funcionario}
                placeholder=" "
                options={funcOptions}
                onChange={(e) => {
                  setFuncionario(e.target.value);
                  if (!e.target.value) {
                    console.log("Limpou");
                    limparDados();
                    localStorage.setItem('funcionario_selecionado', '');
                  }
                }}
              />
            </SelectWrapper>
            <ParallelInputWrapper>
              <InputQuery
                label="Logradouro"
                name="nome"
                value={logradouro}
                placeholder=" "
                isPassword={false}
                onChange={(e) => setLogradouro(e.target.value)}
                readOnly={funcionario}
              />
              <InputSmall
                label="Número"
                name="nome"
                value={numero}
                placeholder=" "
                isPassword={false}
                onChange={(e) => setNumero(e.target.value)}
                readOnly={funcionario}
              />
            </ParallelInputWrapper>
            <ParallelInputWrapper>
              <InputQuery
                label="Bairro"
                name="nome"
                value={bairro}
                placeholder=" "
                isPassword={false}
                onChange={(e) => setBairro(e.target.value)}
                readOnly={funcionario}
              />
              <InputSmall
                label="CEP"
                name="nome"
                value={cep}
                placeholder=" "
                isPassword={false}
                onChange={(e) => setCep(e.target.value)}
                readOnly={funcionario}
              />
            </ParallelInputWrapper>
            <SelectCityWrapper>
              <Select
                label={"Cidade"}
                name="nome"
                value={cidade}
                placeholder=" "
                options={cidadeOpcao}
                onChange={(e) => setCidade(e.target.value)}
                readOnly={funcionario}
              />
            </SelectCityWrapper>
            <ParallelInputWrapper>
              <NumberSelector
                label={"Dias trabalhados"}
                initialValue={22}
                onChange={handleNumberDaysChange}

              />
              <NumberSelector
                label={"Distância máxima raio (m)"}
                initialValue={valorRaio * 1000}
                onChange={(value) => setValorRaio(value / 1000)}
              />
            </ParallelInputWrapper>
            <ParallelInputWrapper>
              <TimePicker label="Entrada" initialTime={HoraEntrada} onChange={handleChangeStartTime} />
              <TimePicker label="Saída" initialTime={HoraSaida} onChange={handleChangeEndTime} />
            </ParallelInputWrapper>
            <DaySelector selectedDays={selectDias} onSelectDay={handleSelectDay} initialDays={selectDias}/>
          </LeftDiv>
         
          <RightDiv>
            <OptionSelector
              selected={tipoConsulta}
              onChange={(e) => setTipoConsulta(e.target.value)}
            />
            {
              tipoConsulta == 'TOD' ? (
                <>
                  <Select
                    label={"Unidade"}
                    name="nome"
                    value={unidadeId}
                    placeholder=" "
                    options={unitOptions}
                    onChange={(e) => {
                      setUnidadeId(e.target.value);
                      handleSelecionaUnidade(e.target.value);
                    }
                    }
                  />
                  <ParallelInputWrapper>
                    <InputQuery
                      label="Logradouro"
                      name="nome"
                      value={logradouroUnidade}
                      placeholder=" "
                      isPassword={false}
                      onChange={(e) => setLogradouroUnidade(e.target.value)}
                      readOnly={!canEdit}
                    />
                    <InputSmall
                      label="Número"
                      name="nome"
                      value={numeroUnidade}
                      placeholder=" "
                      isPassword={false}
                      onChange={(e) => setNumeroUnidade(e.target.value)}
                      readOnly={!canEdit}
                    />
                  </ParallelInputWrapper>
                  <ParallelInputWrapper>
                    <InputQuery
                      label="Bairro"
                      name="nome"
                      value={bairroUnidade}
                      placeholder=" "
                      isPassword={false}
                      onChange={(e) => setBairroUnidade(e.target.value)}
                      readOnly={!canEdit}
                    />
                    <InputSmall
                      label="CEP"
                      name="nome"
                      value={cepUnidade}
                      placeholder=" "
                      isPassword={false}
                      onChange={(e) => setCepUnidade(e.target.value)}
                      readOnly={!canEdit}
                    />
                  </ParallelInputWrapper>
                  <ParallelInputWrapper>
                    <InputQuery
                      label="Cidade"
                      name="nome"
                      value={cidadeUnidade}
                      placeholder=" "
                      isPassword={false}
                      onChange={(e) => setCidadeUnidade(e.target.value)}
                      readOnly={!canEdit}
                    />
                    <InputSmall
                      label="UF"
                      name="nome"
                      value={estadoUnidade}
                      placeholder=" "
                      isPassword={false}
                      onChange={(e) => setEstadoUnidade(e.target.value)}
                      readOnly={!canEdit}
                    />
                  </ParallelInputWrapper>
                  <BlueButtonWrapper>
                    <BlueButton onClick={handleGetRotas}>CONSULTAR</BlueButton>
                  </BlueButtonWrapper>
                </>
              ) : (
                <ListItensDiv>
                  <ListUnidades
                    label="Escolha as Unidades"
                    itens={unidadesDisponiveis}
                    onItemClick={(item) => handleItemClick(item, unidadesDisponiveis, setUnidadesDisponiveis, unidadesSelecionadas, setUnidadesSelecionadas)}
                  />
                  <ListButtonDiv>
                    <BlueButtonConfirmed onClick={() => handleMoveAll(unidadesSelecionadas, setUnidadesSelecionadas, unidadesDisponiveis, setUnidadesDisponiveis)} isConfirmed={botaoRetirarTudo}><FaArrowUpLong /></BlueButtonConfirmed>
                    </ListButtonDiv>
                  <ListUnidades
                    label="Unidades Escolhidas"
                    itens={unidadesSelecionadas}
                    onItemClick={(item) => handleItemClick(item, unidadesSelecionadas, setUnidadesSelecionadas, unidadesDisponiveis, setUnidadesDisponiveis)}
                  />
                  <ConfirmaButtonDiv>
                    <BlueButtonConfirmed onClick={handleGetMelhorUnidade} isConfirmed={podeConsultarMU}>CONSULTAR</BlueButtonConfirmed>
                  </ConfirmaButtonDiv>
                </ListItensDiv>
              )}
            
          </RightDiv>
        </DepthBox>
        <Menu isOpen={isMenuOpen} onClose={handleCloseMenu}>
          <MenuText onClick={handleOpenModal}>Trocar senha</MenuText>
          <ModalPassword isOpen={isModalOpen} onClose={handleCloseModal}>
            <InputFields
              label="Senha"
              name="nome"
              value={Password}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputFields
              label="Nova senha"
              name="nome"
              value={NewPassword}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputFields
              label="Confirmar senha"
              name="nome"
              value={ConfirmPassword}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <BlueButton onClick={handleEditPassword}>Salvar</BlueButton>
          </ModalPassword>
        </Menu>
        {loading && (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
        {isError && (
          <ModalErro 
            titulo="Erro"
            texto={errorMessage}
            botao="Fechar"
            onClose={() => setIsError(false)} />
        )}
      </Container>
    </>
  );
};
export default Home;

