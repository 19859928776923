import styled from "styled-components";
import { azul_primario, branco_primario, cinza_texto, cinza_translucido } from "../../../data/color";
import { fonte_primaria } from "../../../data/font";

export const DivFundo = styled.div`
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: ${cinza_translucido};
    opacity: 0.5;
    z-index: 850;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DivErro = styled.div`
    width: 550px;
    height: 250px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-275px, -125px);
    z-index: 851;
    background-color: ${branco_primario};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 8px;

    @media (max-width: 580px) {
        width: 360px;
        transform: translate(-180px, -125px);
    }
`;

export const DivTexto = styled.div`
    width: 85%;
    height: 90px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const GrandeLabel = styled.label`
    color: ${azul_primario};
    font-family: ${fonte_primaria};
    font-weight: 500;
    font-size: 24px;
    text-align: center;

`;

export const PequenoLabel = styled.label`
    color: ${cinza_texto};
    font-family: ${fonte_primaria};
    font-weight: 100;
    font-size: 18px;
    text-align: center;

    @media (max-width: 580px) {
        font-size: 14px;
    }
`;

export const ConfirmaButton = styled.button`
    width: 85%;
    height: 48px;
    font-family: ${fonte_primaria};
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: ${branco_primario};
    background-color: ${azul_primario};
    border-style: none;
    border-radius: 8px;
    cursor: pointer;
    
    @media (max-width: 580px) {
        font-size: 14px;
    }
`;

export const CloseButtonWrapper = styled.div`
    width: 95%;
    height: 30px;
    display: flex;
    justify-content: right;
    align-items: center;
    position: absolute;
    margin-top: -185px;
    align-self: start;
`;

export const CloseButton = styled.button`
    width: 24px;
    height: 24px;
    border-style: none;
    background-color: ${branco_primario};
    cursor: pointer;
    color: ${cinza_texto};
`;