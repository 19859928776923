import React from "react";
import { CloseButton, CloseButtonWrapper, ConfirmaButton, DivErro, DivFundo, DivLoading, DivTexto, GrandeLabel, PequenoLabel } from "./styles";

import { FaX } from "react-icons/fa6";

const ModalErro = ({ titulo, texto, botao, onClose }) => {
    return (
        <>
            <DivFundo/>
            <DivErro>
                <CloseButtonWrapper>
                    <CloseButton onClick={onClose}><FaX /></CloseButton>
                </CloseButtonWrapper>
                <DivTexto>
                    <GrandeLabel>{titulo}</GrandeLabel>
                    <PequenoLabel>{texto}</PequenoLabel>
                </DivTexto>
                <ConfirmaButton onClick={onClose}>{botao}</ConfirmaButton>
            </DivErro>
        </>
    );
};

export default ModalErro;