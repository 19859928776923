import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation, Link, Route, Routes} from "react-router-dom";

import getTimeOut from '../../data/services/getTimeOut';
import getUnidadeDados from '../../data/services/getUnidadeDados';
import getRotas from '../../data/services/getRotas';
import changePass from '../../data/services/changePass';

import { consultaPath, loginPath, melhorUnidadePath, resultadoPath } from '../../data/pathConfig';
import { 
  Container, 
  DepthBox, 
  DividerWrapper, 
  NomeButton, 
  NomeContainer, 
  NomeLabel, 
  ResultDiv, 
  ResultTableWrapper, 
  SmallDiv, 
  TextTopBarBlueBigWrapper, 
  TextTopBarBlueSmallWrapper, 
  TopBarBlue, 
  TopBarBlueSmallDiv, 
  WhiteTitle, 
  SelectMarkWrapper, 
  SelectMark, 
  ResultWrapper, 
  DataLabelWrapper, 
  DataLabel,
  ConsultaButton,
  ConsultaButtonContainer,
  MenuText,
  VoltarButtonWrapper,
  LoadingSpinner,
  LoadingContainer,
} from './styles';

import TopBar from '../../utils/components/TopBar';
import BlueButton from '../../utils/components/BlueButton';
import Menu from '../../utils/components/Menu';
import ModalPassword from '../../utils/components/ModalPassword';
import InputFields from '../../utils/components/InputFields';
import ModalInfo from '../../utils/components/ModalInfo';
import PopUpErro from '../../utils/components/PopUpErro';

import { FaUser, FaAnglesRight, FaCheck, FaXmark, FaBus } from "react-icons/fa6";
import ModalInfoMU from '../../utils/components/ModalInfoMU';

const MelhorUnidade = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const { dataState = null, funcionarioState = null } = location.state || {};

    const [data, setData] = useState(dataState == null ? 
      JSON.parse(localStorage.getItem('data')) : dataState );
    const [funcionario, setFuncionario] = useState(funcionarioState == null ? 
      JSON.parse(localStorage.getItem('funcionario')) : funcionarioState );

    console.log(data);

    const [token, setToken] = useState(
      localStorage.getItem('token') ? localStorage.getItem('token') : '');

    const [unidades, setUnidades] = useState([]);
    const [selectedUnidIndex, setSelectedUnidIndex] = useState(0);
    const [endereco, setEndereco] = useState('');
    const [unidadeAtual, setUnidadeAtual] = useState('');
    const [custoAtual, setCustoAtual] = useState('');

    const [modalInfo, setModalInfo] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [Password, setPassword] = useState('');
    const [NewPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');

    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [estadoSel, setEstadoSel] = useState(
      localStorage.getItem("estado_selecionado") ? localStorage.getItem("estado_selecionado").replace(/['"]/g, "") : ""
    );

    useEffect(() => {
      const verify = async (token) => {
        const data = await getTimeOut(token);
        if(!data.success) {
          navigate(loginPath);
        }
      }
  
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        verify(storedToken);      
      } else {
        navigate(loginPath);
      }

      if (dataState) {
        localStorage.setItem('data', JSON.stringify(dataState));
      }

      if (funcionarioState) {
        localStorage.setItem('funcionario', JSON.stringify(funcionarioState));
      }
      
      //console.log(data);
      //console.log(funcionario);
    });


    useEffect (() => {
      const numericKeys = Object.keys(data).filter(key => !isNaN(key));
      const newList = [];

      for (let i = 0; i < numericKeys.length - 1; i++) {
        newList.push(data[numericKeys[i]]);
      }
      setEndereco(data[numericKeys.length - 1].endereco_funcionario);
      setUnidades(newList);
    }, [data]);

    const handleProfileClick = () => {
      setIsMenuOpen(true);
    };

    const handleInfoClick = () => {
      setModalInfo(true);
    }

    const handleCloseModalInfo = () => {
      setModalInfo(false);
    }

    const handleVoltar = () => {
      navigate(consultaPath);
    }

    const handleCloseMenu = () => {
      setIsModalOpen(false);
      setIsMenuOpen(false);
    }

    const handleOpenModal = () => {
      setIsModalOpen(true);
    }
  
    const handleCloseModal = () => {
      setIsMenuOpen(false);
      setIsModalOpen(false);
    }

    const handleEditPassword = async () => {
      const editparams = {
        senhaAntiga: Password,
        novaSenha: NewPassword,
      }
      const response = await changePass(token, editparams);
      if (response.success == true) {
        setIsMenuOpen(false);
        setIsModalOpen(false);
        alert("A senha foi trocada");
      }
      else {
        alert("Erro ao alterar senha");
      }
    }

    const handleConsulta = async (index) => {

      setLoading(true);

      /*
      let params = {
        id: data[index].id_unidade
      }
      let response = await getUnidadeDados(token, params);
      if (response.success) {
        if (response.data) {
        } else {
          console.error('Dados de unidade indefinidos ou vazios.');
        }
      }

      params = {
        id_func: funcionario.id_func,
        id_unidade: data[index].id_unidade,
        logradouro: funcionario.logradouro,
        numero: funcionario.numero,
        bairro: funcionario.bairro,
        cep: funcionario.cep,
        cidade: funcionario.cidade,
        estado: funcionario.estado,
        logradouroD: response.data.logradouro,
        numeroD: response.data.numero,
        bairroD: response.data.bairro,
        cepD: response.data.cep,
        cidadeD: response.data.cidade,
        estadoD: response.data.estado,
        tipoDia: {0: 'U'},
        horarioEntrada: funcionario.horarioEntrada,
        horarioSaida: funcionario.horarioSaida,
        dias: funcionario.dias,
        raio: funcionario.raio
      };
      */

      let params = {
        id_func: funcionario.id_func,
        id_unidade: data[index].id_unidade,
        logradouro: funcionario.logradouro,
        numero: funcionario.numero,
        bairro: funcionario.bairro,
        cep: funcionario.cep,
        cidade: funcionario.cidade,
        estado: funcionario.estado,
        tipoDia: {0: 'U'},
        horarioEntrada: funcionario.horarioEntrada,
        horarioSaida: funcionario.horarioSaida,
        dias: funcionario.dias,
        raio: funcionario.raio
      };
      try {
        const response = await getRotas(token, params);
        
        if (response.success === true) {
          setLoading(false);
          console.log(response);
          navigate(resultadoPath, { state: { rotas: response.data, returnPath: melhorUnidadePath } });
        } else {
          setErrorMessage('Erro na consulta!');
          setIsError(true);
          setLoading(false);
        }
      } catch (error) {
        // Se ocorrer um erro durante a requisição
        if (error.response && error.response.status === 500) {
          setErrorMessage('Erro ao fazer consulta.');
        } else {
          setErrorMessage('Erro ao processar a requisição.');
        }
        setIsError(true);
        setLoading(false);
      }
      setLoading(false);
    }

    return (
        <>
          <Container>
            <TopBar onProfileClick={handleProfileClick} estadoSel={estadoSel} setEstadoSel={setEstadoSel} />
            <DepthBox>
              <TopBarBlue><WhiteTitle>Consulta / Melhor Unidade</WhiteTitle></TopBarBlue>
              <NomeContainer>
                <NomeLabel>FUNCIONÁRIO:   {data.nome_funcionario}</NomeLabel>
                <NomeButton onClick={handleInfoClick}><FaUser /><FaAnglesRight /></NomeButton>
                <VoltarButtonWrapper>
                  <BlueButton onClick={handleVoltar} >VOLTAR</BlueButton>
                </VoltarButtonWrapper>
              </NomeContainer>
              <DividerWrapper />
              <SmallDiv>
                <TopBarBlueSmallDiv>
                  <TextTopBarBlueSmallWrapper >#</TextTopBarBlueSmallWrapper>
                  <TextTopBarBlueBigWrapper >Unidade</TextTopBarBlueBigWrapper>
                  <TextTopBarBlueBigWrapper >Linhas por Dia</TextTopBarBlueBigWrapper>
                  <TextTopBarBlueBigWrapper >Distância</TextTopBarBlueBigWrapper>
                  <TextTopBarBlueBigWrapper >Custo Mensal</TextTopBarBlueBigWrapper>
                  <TextTopBarBlueBigWrapper >Economia</TextTopBarBlueBigWrapper>
                  <TextTopBarBlueSmallWrapper >Linhas</TextTopBarBlueSmallWrapper>
                </TopBarBlueSmallDiv>
                <ResultTableWrapper>
                  {
                    unidades && unidades.length > 0 ? (
                      unidades.map((unid, index) => (
                        <ResultDiv key={index} >
                          <ResultWrapper>
                            {(selectedUnidIndex == index) ? (
                              <SelectMarkWrapper>
                                <SelectMark color="#3DE33E" onClick={
                                  () => {setSelectedUnidIndex(index)}
                              }>
                                  <FaCheck style={{color: '#3DE33E'}}/>
                                </SelectMark>
                              </SelectMarkWrapper>
                              ) : (
                                <SelectMarkWrapper>
                                  <SelectMark color="#0A1C5C" onClick={
                                    () => {setSelectedUnidIndex(index)}
                                  }>
                                    <FaXmark style={{color: '#0A1C5C'}}/>
                                  </SelectMark>
                                </SelectMarkWrapper>
                              )
                            }
                            <DataLabelWrapper >
                              <DataLabel>{unid.nome_unidade}</DataLabel>
                            </DataLabelWrapper>
                            <DataLabelWrapper >
                              <DataLabel>{unid.num_linhas}</DataLabel>
                            </DataLabelWrapper>
                            <DataLabelWrapper >
                              <DataLabel>{unid.distancia}</DataLabel>
                            </DataLabelWrapper>
                            <DataLabelWrapper >
                              <DataLabel>R$ {unid.custo_mensal.toFixed(2).toString().replace(".", ",")}</DataLabel>
                            </DataLabelWrapper>
                            <DataLabelWrapper >
                              <DataLabel>R$ {unid.economia.toFixed(2).toString().replace(".", ",")}</DataLabel>
                            </DataLabelWrapper>
                            <ConsultaButtonContainer>
                              <ConsultaButton onClick={() => (handleConsulta(index))}>
                                <FaBus />
                              </ConsultaButton>
                            </ConsultaButtonContainer>
                          </ResultWrapper>
                        </ResultDiv>
                        
                      )))
                    : (
                      <p>Nenhuma unidade</p>
                    )
                  }
                </ResultTableWrapper>
              </SmallDiv>
              
            </DepthBox>
            <Menu isOpen={isMenuOpen} onClose={handleCloseMenu}>
          <MenuText onClick={handleOpenModal}>Trocar senha</MenuText>
          <ModalPassword isOpen={isModalOpen} onClose={handleCloseModal}>
            <InputFields
              label="Senha"
              name="nome"
              value={Password}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputFields
              label="Nova senha"
              name="nome"
              value={NewPassword}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputFields
              label="Confirmar senha"
              name="nome"
              value={ConfirmPassword}
              placeholder=" "
              isPassword={true}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <BlueButton onClick={handleEditPassword}>Salvar</BlueButton>
          </ModalPassword>
        </Menu> 
            {modalInfo && (
              <ModalInfoMU onClose={handleCloseModalInfo} endereco={endereco} data={data} />
            )}
            {loading && (
              <LoadingContainer>
                <LoadingSpinner />
              </LoadingContainer>
            )}
            {isError && (
              <PopUpErro type="error">{errorMessage}</PopUpErro>
            )}
          </Container>
        </>
    );
};

export default MelhorUnidade;